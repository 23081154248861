import FormVisitorInfo from "./Form/PersonalDetails";
import FormVisitorDemo from "./Form/Demographics";
import Controls from "./Form/Controls";
import { useContext } from "react";
import { Form } from "antd";
import FormContext from "../context/formContext";
import OnecardForm from "./Form/OnecardForm";
import { motion } from "framer-motion";
function VisitorForm() {
  const { stage, onSubmit } = useContext(FormContext);

  if (stage === "stage-0") {
    // only show the onecard form until we look up the user.
    return (
      <div className="p-2 m-2 mt-4 flex flex-col gap-2">
        <motion.div
          transition={{ ease: "easeOut", duration: 1.5 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <OnecardForm />
        </motion.div>
      </div>
    );
  }
    return (
      <div className="p-2 m-2 mt-4 flex flex-col gap-2 relative">
        <Form
          id="visitor-form"
          onFinish={onSubmit}
        >
          <div className="flex flex-col lg:flex-row gap-2 mb-3" >
          <FormVisitorInfo />
          <br />
          <FormVisitorDemo /></div>
<div className="flex flex-col items-end">
          <Controls /></div>
        </Form>
      </div>
    );
}

export default VisitorForm;
