import { Navigate } from "react-router-dom";
import LoginForm from "../components/LoginForm";
import { useAuth } from "../hooks/useAuth";
function LoginPage() {
  const { user } = useAuth();
  if (!user) {
    return <LoginForm />;
  }
    return <Navigate to="/dashboard" />;
}

export default LoginPage;
