import { useState } from "react";
import { Radio, ConfigProvider } from "antd";

function CampusSelector({ select }) {
  const [selected, setSelected] = useState();

  const handleSelect = (e) => {
    setSelected(e.target.value);
    select(e.target.value);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Radio: {
            buttonPaddingInline: 20,
          },
        },
      }}
    >
      <Radio.Group
        optionType="button"
        defaultValue={"Truax"}
        buttonStyle="solid"
      >
        <Radio.Button
          className="px-12"
          checked={selected === "Truax"}
          onChange={handleSelect}
          value={"Truax"}
          id="campus-truax"
        >
          Truax
        </Radio.Button>

        <Radio.Button
          checked={selected === "Goodman South"}
          onChange={handleSelect}
          value="Goodman South"
          id="campus-goodman-south"
        >
          Goodman South
        </Radio.Button>
      </Radio.Group>
    </ConfigProvider>
  );
}

export default CampusSelector;
