import { Image, Tooltip } from "antd";
import { useContext } from "react";
import GlobalContext from "../context/globalContext";
import { Link } from "react-router-dom";
import { ExportOutlined } from "@ant-design/icons";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { useAuth } from "../hooks/useAuth";
function Banner() {
	const { stage, campus } = useContext(GlobalContext);
	const {user, logout} = useAuth();
	if (user === null) {
		return (
			<div
				key="bannerSmall"
				className=" flex flex-col items-bottom center relative"
			>
				<Image width={150} src="/img/CupboardLogoLight.png" />
				<Tooltip title="Admin Panel" className="absolute top-0 right-0">
					<Link to="/login">
						<ExportOutlined className="absolute top-0 right-0 cursor-pointer" />
					</Link>
				</Tooltip>
			</div>
		);
	}
  return (
    <div
      key="bannerSmall"
      className=" flex flex-col items-bottom center relative"
    >
      <Image width={150} src="/img/CupboardLogoLight.png" />
      <Tooltip title="Back To The Form" className="absolute top-0 right-0">
        <div onClick={logout} onKeyDown={logout}>
          <ExportOutlined rotate={180} className="absolute top-0 right-0 cursor-pointer" />
        </div>
      </Tooltip>
    </div>
  );
}

export default Banner;
