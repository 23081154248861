import { useContext } from "react";
import { Form, Input, Space } from "antd";
import FormContext from "../../context/formContext";

function FormVisitorDemo() {
  const { _children, adults, seniors, city, zipcode, handleInput } = useContext(FormContext)

  return (
    <div className="flex flex-col border-gray-100 border-2 text-center px-2 rounded-lg justify-evenly gap-4">
      <div className="flex flex-col justify-between  align-middle">
        <div className="text-2xl  text-blue-300">Demographic Info:</div>
        <p className="text-blue-400">How many household members are in each age group?</p></div>
      <Space style={{ maxWidth: 450 }}>
        <Form.Item
          name='_children'
          colon={false}
          label={<label className="text-white ">Under 18:</label>}
          style={{
            display: 'inline-block',
            width: 'calc(90% - 5px)',
            margin: '0 8px',
          }}
          initialValue={_children}
          validateTrigger='onBlur'
          rules={[{ required: true, message:'Error',validateTrigger:'onBlur'  }]}
          id="_children"
        >
          <Input onChange={handleInput} />
        </Form.Item>
        <Form.Item
          name="adults"
          colon={false}
          label={<label className="text-white">18 - 60: </label>}
          style={{
            display: 'inline-block',
            width: 'calc(90% - 10px)',
            margin: '0 8px',
          }}
          initialValue={adults}
          validateTrigger='onBlur'
          rules={[{ required: true, message:'Error' }]}
          id="adults"
        >
          <Input onChange={handleInput} />
        </Form.Item>
        <Form.Item
          name="seniors"
          colon={false}
          label={<label className="text-white ">Over 60: </label>}
          style={{
            display: 'inline-block',
            width: 'calc(90% - 8px)',
            margin: '0 8px',
          }}
          initialValue={seniors}
          rules={[{ required: true, message:'Error'}]}
          type='number'
          id="seniors"
        >
          <Input onChange={handleInput} />
        </Form.Item>
      </Space>
      <Space style={{ maxWidth: "400px" }}>
        <Form.Item
          name="city"
          colon={false}
          label={<label className="text-white">City: </label>}
          initialValue={city}
          rules={[{ required: true, message:'Cannot be empty'}]}
          id="city"
        >

          <Input className="pl-5" onChange={handleInput} />
        </Form.Item>
        <Form.Item
          name="zipcode"
          colon={false}
          label={<label className="text-white">Zipcode: </label>}
          initialValue={zipcode}
          rules={[{ required: true }, { len: 5, message: 'Must be 5 digits'}]}
          id="zipcode"
        >
          <Input
            onChange={handleInput} />
        </Form.Item>
      </Space>
    </div>
  );
}

export default FormVisitorDemo;
