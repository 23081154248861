import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import moment from "moment";

function checkIfTokenIsExpired(token) {
  const currentTime = moment().unix();
  const tokenTime = moment(token).unix();
  return currentTime > tokenTime;
}

export const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();
  if (!user) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }
  if(checkIfTokenIsExpired(user?.sessionExpires) || user.sessionToken === undefined) {
    // user was authenticated but token is expired
    window.localStorage.removeItem("user");
  }
  return <Outlet />
};