import VisitorForm from "../components/VisitorForm";
import { FormProvider } from "../context/formContext";
function FormPage() {
  return (
    <FormProvider>
      <div className={"flex flex-col items-center "}>
        <VisitorForm />
      </div>
    </FormProvider>
  );
}

export default FormPage;
