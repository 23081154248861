import { createContext, useState, useContext } from "react";
import { toast } from "react-toastify";
import GlobalContext from "./globalContext";
export const FormContext = createContext();
export const FormProvider = ({ children }) => {
	const { stage, setStage } = useContext(GlobalContext);
	const [campus, setCampus] = useState("Truax");
	const [onecard, setOnecard] = useState("");
	const [firstname, setFirstname] = useState("");
	const [lastname, setLastname] = useState("");
	const [email, setEmail] = useState("");
	const [zipcode, setZipcode] = useState("");
	const [city, setCity] = useState("");
	const [_children, setChildren] = useState(0);
	const [adults, setAdults] = useState(0);
	const [seniors, setSeniors] = useState(0);

	const handleInput = (e) => {
		switch (e.target.id) {
			case "firstname": {
				setFirstname(e.target.value);
				break;
			}
			case "lastname": {
				setLastname(e.target.value);
				break;
			}
			case "onecard": {
				setOnecard(e.target.value);
				break;
			}
			case "email": {
				setEmail(e.target.value);
				break;
			}
			case "_children": {
				setChildren(Number(e.target.value));
				break;
			}
			case "adults": {
				setAdults(Number(e.target.value));
				break;
			}
			case "seniors": {
				setSeniors(Number(e.target.value));
				break;
			}
			case "city": {
				setCity(e.target.value);
				break;
			}
			case "zipcode": {
				setZipcode(e.target.value);
				break;
			}
			default: {
				console.log(e.target);
			}
		}
	};

	const populateValues = ({
		onecard,
		firstname,
		lastname,
		email,
		zipcode,
		city,
		_children,
		adults,
		seniors,
	}) => {
		setOnecard(onecard ? String(onecard) : "");
		setFirstname(firstname ? firstname : "");
		setLastname(lastname ? lastname : "");
		setEmail(email ? email : email);
		setZipcode(zipcode ? String(zipcode) : "53714");
		setCity(city ? city : "Madison");
		setChildren(_children ? String(_children) : "0");
		setAdults(adults ? String(adults) : "0");
		setSeniors(seniors ? String(seniors) : "0");
	};
	const submitOnecard = async (e) => {
		try {
			e.preventDefault();
			if (onecard.length < 7) {
				return toast(
					"Please enter a valid 7 digit onecard or swipe your onecard.",
					{ autoClose: 5000 },
				);
			}
			// TODO: Submit to server to check if prior visits
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}api/onecard`,
				{
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({ onecard }),
					mode: "cors",
				},
			);
			if (response.status !== 200) {
				return toast(await response.text(), { autoClose: 5000 });
			}
			const jsonData = await response.json();
			console.log(jsonData);
			setStage("stage-2");
			populateValues(jsonData);
		} catch (error) {
			console.log(error);
			return toast(
				"There was an error searching for your onecard. Please try again later.",
				{ autoClose: 5000 },
			);
		}
	};

	const onSubmit = async (values) => {
    values.campus = campus || 'Truax'
		const response = await fetch(
			`${process.env.REACT_APP_BACKEND_URL}api/visit`,
			{
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(values),
				mode: "cors",
			},
		);
		if (response.status !== 201) {
			return toast(await response.text(), { autoClose: 5000 });
		}
		toast("Your visit has been recorded.", { autoClose: 5000 });
		return setTimeout(() => {
			setStage("stage-0");
		}, 2000);
	};
	return (
		<FormContext.Provider
			value={{
				stage,
				campus,
				onecard,
				firstname,
				lastname,
				email,
				zipcode,
				city,
				_children,
				adults,
				seniors,

				setStage,
				setCampus,
				setOnecard,
				setFirstname,
				setLastname,
				setEmail,
				setZipcode,
				setCity,
				setChildren,
				setAdults,
				setSeniors,
				submitOnecard,
				onSubmit,
				handleInput,
			}}
		>
			{children}
		</FormContext.Provider>
	);
};
export default FormContext;
