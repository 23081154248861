import { useState, useEffect } from "react";
import { useContext } from "react";
import { createContext } from "react";
import { useAuth } from "./useAuth";
import { toast } from "react-toastify";

const AdminPanelContext = createContext();

export const AdminPanelProvider = ({ children }) => {
	const [visits, setVisits] = useState([]);
	const [visitors, setVisitors] = useState([]);
	const [dataLoading, setDataLoading] = useState(true);
	const [selectedView, setSelectedView] = useState("visits"); //values - [visits], visitors, userSettings, emailSettings
	const { user } = useAuth();
	useEffect(() => {
		fetchAdminSummary();
	}, []);
	const addKeyToObjects = (array) => {
		return array.map((obj, index) => {
			return { key: index, ...obj };
		});
	};
	function visitorsFromVisits(visits) {
		const visitors = [];

		for (const visit of visits) {
			if (
				!visitors.some((visitor) => visitor.onecard === visit.visitor.onecard)
			) {
				visitors.push(visit.visitor);
			}
		}

		return addKeyToObjects(visitors);
	}

	const fetchAdminSummary = async () => {
		setDataLoading(true);
		const targetEndpoint = "api/admin";
		const response = await fetch(
			`${process.env.REACT_APP_BACKEND_URL}${targetEndpoint}`,
			{
				method: "POST",
				mode: "cors",
				credentials: "include",
				headers: {
					"Content-Type": "application/json",
					"X-Auth-Token": user.sessionToken,
				},
			},
		);
		if (response.status === 400) return await response.text();
		if (response.status === 401) return window.location.replace("/login");
		const data = await response.json();
		setVisits(addKeyToObjects(data));
		setVisitors(visitorsFromVisits(data));
		setDataLoading(false);
		return null;
	};

	const onExportVisitsFinish = async (fieldsValue) => {
		const rangeValue = fieldsValue["range-picker"];
		const values = {
			exportFrom: rangeValue[0].format("YYYY-MM-DD HH:mm a"),
			exportTo: rangeValue[1].format("YYYY-MM-DD HH:mm a"),
		};
		const response = await fetch(
			`${process.env.REACT_APP_BACKEND_URL}api/admin/export`,
			{
				method: "POST",
				mode: "cors",
				credentials: "include",
				headers: {
					"Content-Type": "application/json",
					"X-Auth-Token": user.sessionToken,
				},
				body: JSON.stringify(values),
			},
		);
		if (response.status === 400) return await response.text();
		if (response.status === 401) return window.location.replace("/login");
		const blobData = await response.blob();
		const url = window.URL.createObjectURL(blobData);
		const a = document.createElement("a");
		a.style.display = "none";
		a.href = url;
		// the filename you want
		a.download = "PantryVisits.csv";
		document.body.appendChild(a);
		a.click();
		window.URL.revokeObjectURL(url);
		toast("Your file is ready to be downloaded");
	};

	const onExportVisitorsFinish = async () => {
		const response = await fetch(
			`${process.env.REACT_APP_BACKEND_URL}api/admin/exportvisitors`,
			{
				method: "POST",
				mode: "cors",
				credentials: "include",
				headers: {
					"Content-Type": "application/json",
					"X-Auth-Token": user.sessionToken,
				},
			},
		);
		if (response.status === 400) return await response.text();
		if (response.status === 401) return window.location.replace("/login");
		const blobData = await response.blob();
		const url = window.URL.createObjectURL(blobData);
		const a = document.createElement("a");
		a.style.display = "none";
		a.href = url;
		// the filename you want
		a.download = "PantryVisitors.csv";
		document.body.appendChild(a);
		a.click();
		window.URL.revokeObjectURL(url);
		toast("Your file is ready to be downloaded");
	};

	return (
		<AdminPanelContext.Provider
			value={{
				visits,
				visitors,
				selectedView,
				dataLoading,
				fetchAdminSummary,
				setSelectedView,
				onExportVisitsFinish,
				onExportVisitorsFinish,
			}}
		>
			{children}
		</AdminPanelContext.Provider>
	);
};
export const useAdmin = () => {
	return useContext(AdminPanelContext);
};
