import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Outlet } from "react-router-dom";
import Banner from "../components/Banner";
import { GlobalProvider } from "../context/globalContext";
import { AuthProvider } from "../hooks/useAuth";

function MainLayout() {
  return (
    <AuthProvider>
      <GlobalProvider>
        <div className={"mt-10 flex flex-row md:flex-col items-center"}>
          <Banner />
        </div>
        <div className={"mt-10 flex flex-row md:flex-col items-center "}>
          <Outlet />
        </div>
        <ToastContainer hideProgressBar={true} />
      </GlobalProvider>
    </AuthProvider>
  );
}

export default MainLayout;
