import { Flex, Space } from "antd";
import Sidebar from "../components/AdminPanel/Sidebar";
import DataTable from "../components/AdminPanel/DataTable";
import VisitorCard from "../components/AdminPanel/VisitorCard";
import { AdminPanelProvider } from "../hooks/useAdmin";
import ExportVisitsForm from "../components/AdminPanel/ExportVisitsForm";

function DashboardPage() {
	return (
		<AdminPanelProvider>
			<Flex gap="large" justify="space-around">
				<Sidebar />
				<div>
					<DataTable />

				</div>
			</Flex>
		</AdminPanelProvider>
	);
}

export default DashboardPage;
