import { useContext } from "react";
import FormContext from "../../context/formContext";
import { Tooltip, Form, Input } from "antd";

function FormVisitorInfo() {
  const {
    firstname,
    lastname,
    onecard,
    email,
    handleInput
  } = useContext(FormContext);
  return (
    <div className="flex flex-col border-2 border-white rounded-lg p-2 pb-0">
      <Form.Item
        name="firstname"
        colon={false}
        label={<label className="text-white">First Name: </label>}
        rules={[{ required: true, message: 'Please enter a first name' }]}
        initialValue={firstname}

      >
        <Input id="firstname" onChange={handleInput} />
      </Form.Item>

      <Form.Item
        name="lastname"
        colon={false}
        label={<label className="text-white">Last Name: </label>}
        rules={[{ required: true, message: 'Please enter a last name' }]}
        initialValue={lastname}
      >
        <Input id="lastname" onChange={handleInput} />
      </Form.Item>
      <Tooltip title="Onecard is disabled to prevent duplicate records.">
        <Form.Item
          name="onecard"
          colon={false}
          label={<label className="text-white">Onecard: </label>}
          initialValue={onecard}
          rules={[{ len: 7, message: 'Onecard must be 7 digits long', validateTrigger:'onSubmit' }]}

        >
          <Input id="onecard" onChange={handleInput} style={{ color: 'white' }} disabled={onecard !==""} />
        </Form.Item></Tooltip>
      <Form.Item
        colon={false}
        label={<label className="text-white">Email:  </label>}
        name="email"
        rules={[{ required: true, pattern: /^[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*$/gm, message: 'Please enter a valid email address',validateTrigger:'onBlur' }]}
        initialValue={email}
              >
        <Input id="email" onChange={handleInput}/>
      </Form.Item>
    </div>
  );
}

export default FormVisitorInfo;
