import { Button, DatePicker, Form } from "antd";
import { useAdmin } from "../../hooks/useAdmin";
import Title from "antd/es/skeleton/Title";
const { RangePicker } = DatePicker;

const rangeConfig = {
	rules: [
		{
			type: "array",
			required: true,
			message: "Please select date range!",
		},
	],
};

const ExportVisitsForm = () => {
	const { onExportVisitsFinish } = useAdmin();
	return (
		<div className="mt-3">
			<Form name="exportVisits" onFinish={onExportVisitsFinish}>
				<Form.Item
					name="range-picker"
					label={<div className="text-white">Export Visits</div>}
					{...rangeConfig}
				>
					<RangePicker />
				</Form.Item>
				<Button className="text-white" type="primary" htmlType="submit">
					Submit
				</Button>
			</Form>
		</div>
	);
};

export default ExportVisitsForm;
