import CampusSelector from "./CampusSelector";
import { useContext } from "react";
import { Input } from "antd";
import FormContext from "../../context/formContext";

function OnecardForm() {
  const { setCampus, submitOnecard, handleInput } = useContext(FormContext);
  return (
    <div>
      <form id="onecard-form" className="flex flex-col  gap-y-5 text-center">
        <div className="flex-auto">
          <h1>Select your campus</h1>
          <CampusSelector
            select={(campus) => {
              setCampus(campus);
            }}
          />
        </div>
        <div className="flex-auto">
          Swipe or Enter your Onecard ID
          <Input
          className="text-center"
            id="onecard"
            onChange={handleInput}
            onPressEnter={submitOnecard}
          />
        </div>
      </form>
    </div>
  );
}

export default OnecardForm;
