import { Form, Input, Button, Checkbox } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import ErrorMessage from "./ErrorMessage";
import { useAuth } from "../hooks/useAuth";

const LoginForm = () => {
  const { email, password, error, onChange, onFinish } = useAuth();

  return (
    <>
    <Form
      name="login"
      className="flex flex-col center login-form"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
    >
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: "Please input your email address!",
          },
        ]}
      >
        <Input
        name="email"
          onChange={onChange}
          value={email}
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="Email"
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: "Please input your password!",
          },
        ]}
      >
        <Input
        name="password"
          onChange={onChange}
          value={password}
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item>
        <Form.Item
          name="remember"
          valuePropName="checked"
          noStyle
        >
          <Checkbox className="text-white" name="remember">Remember me</Checkbox>
        </Form.Item>

        <Link  className="login-form-forgot text-white">Forgot password</Link>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit"  className="login-form-button">
          Log in
        </Button>
      </Form.Item>
      <ErrorMessage message={error} />
    </Form>
    <Link to="/">Back</Link>
    </>
  );
};
export default LoginForm;
