// src/hooks/useAuth.jsx

import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import { useState } from "react";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);
  const [error, setError] = useState("");

  const onChange = (e) => {
    switch (e.target.name) {
      case "email":
        setEmail(e.target.value);
        break;
      case "password":
        setPassword(e.target.value);
        break;
      case "remember":
        setRemember(e.target.checked);
        break;
      default:
        break;
    }
  };
  const login = async (values) => {
    try {
      const { email, password } = values;
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}api/auth/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
          mode: "cors",
          credentials: 'include'
        }
      );
      const data = await response.json();
      console.log(`reponse data ${JSON.stringify(data)}`)
      if (response === "Unauthorized") {
        setError("Invalid email or password");
      } else return data;
    } catch (error) {
      setError(error);
    }
  };

  // call this function when you want to authenticate the user
  const onFinish = async (data) => {
    const loggedInUser = await login(data)
    console.log(`Logged in user: ${loggedInUser}`)
    setUser(loggedInUser);
    navigate("/dashboard");
  };

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
    navigate("/", { replace: true });
  };

  const value = useMemo(
    () => ({
      user
    }),
    [user]
  );
  return (
    <AuthContext.Provider value={{value, user, email, logout, password, onChange, onFinish}}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
