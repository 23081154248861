import { createContext, useState } from "react";
export const GlobalContext = createContext();
export const GlobalProvider = ({ children }) => {
  const [stage, setStage] = useState("stage-0");
  const [selectedView, setSelectedView] = useState("visits"); //values - [visits], visitors, userSettings, emailSettings

  return (
    <GlobalContext.Provider value={{ stage, selectedView, setSelectedView, setStage }}>
      {children}
    </GlobalContext.Provider>
  );
};
export default GlobalContext;
