
function Controls() {
  return (
    <div>
      <button
        type="button"
        onClick={() => { window.location.reload() }}
        className="text-white bg-zinc-600 border border-slate-300 focus:outline-none hover:bg-red-500 hover:text-black focus:ring-4 focus:ring-slate-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 "
      >
        Start Over
      </button>
      <button
        type="submit"
        className="focus:outline-none text-white bg-sky-500 hover:bg-blue-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
      >Submit
      </button>
    </div>
  );
}
export default Controls;
